.inner-card-wapper {
  @apply grid grid-cols-2 gap-24 mt-12 max_md:gap-16 max_s:gap-7;
}
.dashboard-section .inner-card {
  @apply w-[150px] max_md:w-[120px] max_s:w-[115px] h-[150px] max_md:h-[120px] max_s:h-[115px] bg-dashboardCard rounded-[26px] mb-6 cursor-pointer border border-solid border-borderCard flex items-center justify-center;
}
.inner-card {
  @apply flex flex-col w-[150px] h-[150px] bg-primary rounded-[26px] mb-6 cursor-pointer border border-solid border-outlineColor flex items-center justify-center;
}
.inner-card svg {
  @apply max_md:w-10 max_md:w-10;
}
.inner-card svg {
  @apply max_s:w-8 max_s:w-8;
}
.inner-card p {
  @apply text-primaryContent;
}
.inner-card p {
  @apply max_md:text-sm;
}
.inner-card p {
  @apply max_s:text-[12px];
}
.dashboardHeader{
  @apply mb-11 text-center
}
.dashboardCards {
  @apply text-center break-words;
}
