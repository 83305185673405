.qr-card-section {
  @apply px-8 py-8 bg-neutral mb-5 rounded-sm;
  display: grid;
  grid-template-columns: auto 200px;
}

.qr-card-section div:last-child,
.qr-info-section p:last-child {
  @apply mb-0
}
.add-unit-section{
  @apply !grid-cols-1
}
.add-unit-section .qr-info-section{
  @apply flex justify-between items-center
}
.add-unit-section .qr-label{
  @apply !mb-0
}
.qr-label {
  @apply mb-3 text-left !important
}

.qr-title {
  @apply text-lg font-bold;
}
.qr-value{
  @apply text-base
}
.qr-url{
  color: #1C5DAF;
  font-size: 16px;
}
.action-block{
  @apply max_s:text-center
}
.action-block button{
  @apply mr-5 max_s:mr-0;
}
.qr-code-section {
  padding: 20px;
  background-color: white;
  align-items: center;
  display: flex;
}

/* .qr-card-section:last-child {
  margin-bottom: 0;
} */

/* Add QR Code form */
.qr-block {
  @apply border border-solid border-outlineColor rounded-sm p-8
}

.qr-preview-block {
  @apply max-w-[300px] m-auto
}
@media (max-width: 768px) {
  .qr-card-section {
    @apply px-4 grid-cols-1
  }
}
.btn-postion{
  @apply absolute right-0 top-10 flex justify-center items-center
}
.btn-postion a{
  @apply no-underline text-neutral
}
.btnDisable{
  @apply !bg-disabled
}