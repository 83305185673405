/*Font font A styles*/
.mw-font-a body,
.mw-font-a div {
  font-size: 1rem;
  line-height: 1.5rem;
}

.mw-font-a h1 {
  font-size: 1.75rem;
  line-height: 2.25rem;
}
.mw-font-a h2 {
  font-size: 1.5rem;
  line-height: 2rem;
}
.mw-font-a h3 {
  font-size: 1.375rem;
  line-height: 1.875rem;
}
.mw-font-a h4 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.mw-font-a h5 {
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.mw-font-a h6 {
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.mw-font-a small {
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.mw-font-a p,
.mw-font-a span {
  font-size: 1rem;
  line-height: 1.5rem;
}
.mw-font-a ul,
.mw-font-a li {
  font-size: 1rem;
  line-height: 1.5rem;
}
.mw-font-a button {
  font-size: 1rem;
  line-height: 1.5rem;
}

.mw-font-a a {
  font-size: 1rem;
  line-height: 1.5rem;
}
.mw-font-a input,
.mw-font-a input::placeholder,
.mw-font-a textarea::placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
}
.mw-font-a label {
  font-size: 1.125rem;
  line-height: 1.625rem;
}
.mw-font-a button {
  font-size: 1.125rem;
  line-height: 1.625rem;
}
.mw-font-a legend {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: bold;
}
.mw-font-a li > a {
  font-size: 1rem;
  line-height: 1.5rem;
}

.mw-font-a .calendarContainer .dayWrapper button {
  font-size: 1rem;
  line-height: 1.5rem;
}
.mw-font-a .calendarContainer .heading .title {
  font-size: 1rem;
  line-height: 1.5rem;
}
@media (min-width:768px) {
  .mw-font-a body,
  .mw-font-a div {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  
  .mw-font-a h1 {
    font-size: 2.25rem;
    line-height: 2.875rem;
  }
  .mw-font-a h2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .mw-font-a h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .mw-font-a h4 {
    font-size: 1.375rem;
    line-height: 1.875rem;
  }
  .mw-font-a h5 {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  .mw-font-a h6 {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  .mw-font-a small {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .mw-font-a p,
  .mw-font-a span {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  .mw-font-a ul,
  .mw-font-a li {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  .mw-font-a button {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  
  .mw-font-a a {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  .mw-font-a input,
  .mw-font-a input::placeholder,
  .mw-font-a textarea::placeholder {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  .mw-font-a .calendarContainer .dayWrapper button {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  .mw-font-a .calendarContainer .heading .title {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
}
@media(min-width:576px){
  .mw-font-c li > a {
    font-size: 1.5rem;
    line-height:2rem;
  }
  .mw-font-a li > a {
    font-size: 1.125rem;
    line-height:1.625rem;
  }
}
.toggel-color{
  @apply text-buttonColor font-bold
}
.toggel-color-off{
  @apply text-primaryContent
}