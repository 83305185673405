@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

/* Box sizing rules */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

li {
  margin-bottom: 10px;
}

/* Set core root defaults */

html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}

/* A elements that don't have a class get default styles */

a:not([class]) {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
  font: inherit;
}

button,
a {
  cursor: pointer;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    -webkit-animation-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

p {
  padding: 0;
}

/* Import font family */

/* General styles */

body {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--neutral) / var(--tw-bg-opacity));
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(var(--primary-content) / var(--tw-text-opacity));
  -webkit-print-color-adjust: exact;
}

.content-container {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 767px;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--container) / var(--tw-bg-opacity));
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 90px;
  padding-bottom: 90px;
}

@media (max-width: 768px) {
  .content-container {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-top: 4rem;
  }
}

@media (max-width: 530px) {
  .content-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-top: 3rem;
  }
}

.content-container.sm {
  max-width: 600px;
}

.content-container.md {
  max-width: 767px;
}

.content-container.lg {
  max-width: 1100px;
}

.content-container.xlg {
  max-width: 1565px;
}

.page-head {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.375rem;
  line-height: 1.875rem;
}
.demoNextBtn{
  position: absolute;
  right: 32px;
}

/* Dashboard page styles */

.dashboard-card {
  border-radius: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(var(--outline) / 0.3);
  --tw-bg-opacity: 1;
  background-color: rgb(var(--accentCard) / var(--tw-bg-opacity));
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 15rem;
  padding-bottom: 11rem;
  --tw-text-opacity: 1;
  color: rgb(var(--cardContent) / var(--tw-text-opacity));
}

@media (max-width: 768px) {
  .dashboard-card {
    padding: 3rem;
  }
}

@media (max-width: 530px) {
  .dashboard-card {
    padding: 1.5rem;
  }
}

/* Reset fieldset styles */

.reset-fieldset {
  border-width: 0px;
  padding: 0px;
}

/* Remove button style */

.clear-button-style {
  border-width: 0px;
  background-color: var(--transparent);
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(var(--primary-content) / var(--tw-text-opacity));
  outline-width: 0px;
}

.clear-button-style:disabled {
  cursor: default;
  color: rgb(var(--primary-content) / 0.5);
}

.clear-button-style:disabled:hover {
  color: rgb(var(--primary-content) / 0.5);
}

.clear-button-style:disabled svg {
  opacity: 0.5;
}

.clear-button-style:disabled svg:hover {
  opacity: 0.5;
}

/* Clear anchor tag style */

.clear-link-style {
  border-width: 0px;
  border-bottom-width: 2px;
  border-style: solid;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(var(--primary-content) / var(--tw-text-opacity));
  text-decoration-line: none;
}

.clear-link-style:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--buttonColor) / var(--tw-text-opacity));
}

/* Apply colours to svg icons */

.icon-view {
  fill: rgb(var(--primary-content) / 1);
}

.icon-view:hover {
  fill: rgb(var(--buttonColor) / 1);
}

/* check-box-svg  size*/

.check-box-svg {
  height: 2rem;
  width: 2rem;
}

@media (max-width: 768px) {
  .check-box-svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

@media (max-width: 530px) {
  .check-box-svg {
    height: 1rem;
    width: 1rem;
  }
}

.check-box-svg .color-first {
  fill: rgb(var(--buttonColor) / 1);
}

.check-box-svg .color-sec {
  fill: rgb(var(--buttonText) / 1);
}

/* Accessibility keyboard navigation styles */

a:focus-visible,
select:focus-visible {
  border-radius: 2px !important;
  outline-width: 2px !important;
  outline-offset: 4px !important;
  outline-color: rgb(var(--buttonColor) / 1) !important;
}

button:focus-visible {
  outline-width: 2px !important;
  outline-offset: 8px !important;
  outline-color: rgb(var(--buttonColor) / 1) !important;
}

.round-group:focus-visible {
  border-radius: 1px !important;
  outline-width: 2px !important;
  outline-offset: 8px !important;
  outline-color: rgb(var(--buttonColor) / 1) !important;
}

.round-group input:focus-visible + span {
  display: none;
}

/* Add transistion with this class */

.add-transistion {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

/* Click menu */

.click_menu {
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(var(--buttonColor) / var(--tw-text-opacity));
  text-decoration-line: underline;
}

/* Table css */

.table-visible {
  min-height: 290px !important;
  overflow-y: visible !important;
}

.table-heatmap-view .rdt_TableCell {
  padding-right: 1px !important;
}

.table-heatmap-view .table-blk .rdt_TableRow {
  padding-top: 0px !important;
  padding-bottom: 1px !important;
}

.headmap-circle-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.no-answer {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.square {
  margin-left: 0px !important;
  margin-right: 0px !important;
  min-height: 60px !important;
  min-width: 60px !important;
  cursor: pointer;
  border-width: 0px !important;
}

.circle-bock {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.circle-bock .circle {
  margin-right: 0.5rem;
}

.circle {
  height: 25px;
  width: 25px;
  border-radius: 9999px;
}

.circle.avg-low,
.low-heat-map {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--legend-low) / var(--tw-bg-opacity));
}

.circle.avg-moderate-low,
.moderate-low-heat-map {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--legend-moderate) / var(--tw-bg-opacity));
}

.circle.avg-Sub,
.mid-heat-map {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--legend-Satisfactory) / var(--tw-bg-opacity));
}

.circle.avg-good,
.good-heat-map {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--legend-Good) / var(--tw-bg-opacity));
}

.circle.avg-great,
.great-heat-map {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--legend-Great) / var(--tw-bg-opacity));
}

.feedback-heat-map{
  --tw-bg-opacity: 1;
  background-color: rgb(var(--legend-feedback) / var(--tw-bg-opacity));
}

/* Table css end */

.select-heatmap-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 639px) {
  .select-heatmap-group {
    width: 100%;
  }
}

.select-group-section {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.75rem;
}

@media (max-width: 992px) {
  .select-group-section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.select-group-block {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.25rem;
}

@media (max-width: 639px) {
  .select-group-block {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.select-range-block {
  width: 100%;
  max-width: 270px;
}

@media (max-width: 639px) {
  .select-range-block {
    margin-top: 1.25rem;
    max-width: 100%;
  }
}

/* Table view css */

.table-headmap-info {
  margin-top: 40px;
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 1.25rem;
  border-radius: 5px;
}

@media (max-width: 992px) {
  .table-headmap-info {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  .table-headmap-info {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 530px) {
  .table-headmap-info {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.table-heatmap-card-info {
  display: flex;
  min-height: 110px;
  min-width: 160px;
  flex-direction: column;
  justify-content: space-between;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--neutral) / var(--tw-bg-opacity));
  padding: 0.75rem;
}

.table-view-card-section {
  margin-top: 40px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 1.25rem;
  border-radius: 5px;
}

@media (max-width: 992px) {
  .table-view-card-section {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  .table-view-card-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 530px) {
  .table-view-card-section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.table-view-card-info {
  display: flex;
  min-height: 110px;
  min-width: 160px;
  flex-direction: column;
  justify-content: space-between;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--neutral) / var(--tw-bg-opacity));
  padding: 0.75rem;
}

.table-view-card-info p {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 700;
}

.table-view-card-info p:last-child {
  text-align: right;
}

.table-view-card-info:nth-child(2) {
  grid-row: span 2 / span 2;
}

.highcharts-markers image {
  -webkit-transform: translate(-14px, -25px);
  transform: translate(-14px, -25px);
}

.print-icon-block {
  position: absolute;
  right: 0px;
  top: -15px;
  display: grid;
  justify-content: center;
  gap: 0.25rem;
}

@media (max-width: 768px) {
  .print-icon-block {
    top: -40px;
  }
}

.fb-notfound-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: uppercase;
}

@media (max-width: 530px) {
  .fb-notfound-title {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
}

.fb-notfound-session {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
}

@media (max-width: 530px) {
  .fb-notfound-session {
    padding: 1.25rem;
  }
}

.fb-notfound-text {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (max-width: 530px) {
  .fb-notfound-text {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
}

.not-found-section .dashboard-card {
  display: flex;
  min-height: 400px;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.not-found-section .dashboard-card .text-logo.not-found-label {
  max-width: 100%;
}

.popper-btn {
  visibility: hidden;
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 0px !important;
  min-width: 60px !important;
}

.heatmap-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 50;
  cursor: pointer;
}
.general-feedback-answer{
  @apply font-normal pl-2
}

.heatmap-popup {
  z-index: 10;
  min-height: 40px;
  width: 235px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(var(--neutral) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1rem;
  --tw-shadow: 0px 4px 12px rgb(0 0 0 /25%);
  --tw-shadow-colored: 0px 4px 12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.heatmap-popup p {
  max-height: 150px;
  overflow-y: auto;
  font-weight: 700;
}

.heatmap-popup .info-text {
  font-weight: 400;
}

.heatmap-popup[data-popper-placement*="bottom"] .popper-arrow {
  top: 0;
  left: 0;
  margin-top: -10px;
  width: 3em;
  height: 1em;
}

.heatmap-popup[data-popper-placement*="bottom"] .popper-arrow::before {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
}

.popper-arrow::before {
  content: "";
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
}

.heatmap-popup[data-popper-placement*="top"] .popper-arrow {
  bottom: 0;
  left: 0;
  margin-bottom: -15px;
  width: 3em;
  height: 1em;
}

.heatmap-popup[data-popper-placement*="top"] .popper-arrow::before {
  border-width: 10px 10px 0 10px;
  border-color: #fff transparent transparent transparent;
}

.heatmap-popup[data-popper-placement*="right"] .popper-arrow {
  left: 0;
  margin-left: -10px;
  height: 3em;
  width: 1em;
}

.heatmap-popup[data-popper-placement*="right"] .popper-arrow::before {
  border-width: 10px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
}

.heatmap-popup[data-popper-placement*="left"] .popper-arrow {
  right: 0;
  margin-right: -10;
  height: 3em;
  width: 1em;
}

.heatmap-popup[data-popper-placement*="left"] .popper-arrow::before {
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #fff;
}

/* Default */

.theme-a {
  --primary: 156 208 208;
  --border-card:156 208 208; 
  --primary-content: 46 36 78;
  --secondary: 211 211 235;
  --secondary-content: 46 36 78;
  --accent: 233 244 244;
  --accentCard: 233 244 244;
  --accent-content: 46 36 78;
  --neutral: 240 240 240;
  --buttonColor: 48 93 169;
  --buttonText: 255 255 255;
  --card: 255 255 255;
  --card-content: 46 36 78;
  --cardContent: 46 36 78;
  --container: 255 255 255;
  --outline: 112 112 112;
  --info: 101 60 136;
  --success: 22 163 74;
  --warning: 217 119 6;
  --error: 220 38 38;
  --disabled: 198 198 198;
  --transparent: transparent;
  --legend-low: 229 87 87;
  --legend-moderate: 251 190 107;
  --legend-Satisfactory: 255 187 78;
  --legend-Good: 98 185 118;
  --legend-Great: 0 144 33;
  --legend-feedback:48 93 169;
  --avatar-bg: 255 255 255;
  --feedback-bg:250 191 95;
  --dashboard-card:233 244 244;
  --nhs-bg:0 104 179;
  --shsc-bg:0 0 0

}

/* Dark */

.theme-b {
  --primary: 29 27 30;
  --border-card: 198 198 198; 
  --primary-content: 188 188 188;
  --secondary: 44 44 46;
  --secondary-content: 188 188 188;
  --accent: 29 27 30;
  --accentCard: 29 27 30;
  --accent-content: 255 255 255;
  --neutral: 58 58 58;
  --buttonColor: 90 200 250;
  --buttonText: 25 22 22;
  --card: 29 27 30;
  --card-content: 188 188 188;
  --cardContent: 188 188 188;
  --container: 39 39 41;
  --outline: 96 96 96;
  --info: 114 77 146;
  --success: 22 163 74;
  --warning: 217 119 6;
  --error: 220 38 38;
  --disabled: 198 198 198;
  --transparent: transparent;
  --avatar-bg: 255 255 255;
  --feedback-bg:29 27 30;
  --dashboard-card:29 27 30;
  --nhs-bg:255 255 255;
  --shsc-bg:255 255 255
}

/* Calm mood */

.theme-c {
  --primary: 246 241 240;
  --border-card: 246 241 240; 
  --primary-content: 46 36 78;
  --secondary: 246 241 240;
  --secondary-content: 46 36 78;
  --accent: 79 126 128;
  --accentCard: 79 126 128;
  --accent-content: 255 255 255;
  --neutral: 240 240 240;
  --buttonColor: 228 121 77;
  --buttonText: 29 27 30;
  --card: 255 255 255;
  --card-content: 255 255 255;
  --cardContent: 255 255 255;
  --container: 255 255 255;
  --outline: 96 96 96;
  --info: 114 77 146;
  --success: 22 163 74;
  --warning: 217 119 6;
  --error: 220 38 38;
  --disabled: 198 198 198;
  --transparent: transparent;
  --avatar-bg: 255 255 255;
  --feedback-bg:250 191 95;
  --dashboard-card:246 241 240;
  --nhs-bg:0 104 179;
  --shsc-bg:0 0 0
}

/* Visibility */

.theme-d {
  --primary: 255 255 255;
  --border-card: 11 24 30; 
  --primary-content: 11 24 30;
  --secondary: 255 255 255;
  --secondary-content: 11 24 30;
  --accent: 11 24 30;
  --accentCard: 11 24 30;
  --accent-content: 247 247 247;
  --neutral: 243 243 243;
  --buttonColor: 255 157 0;
  --buttonText: 11 24 30;
  --card: 255 255 255;
  --card-content: 255 255 255;
  --cardContent: 255 255 255;
  --container: 255 255 255;
  --outline: 96 96 96;
  --info: 101 60 136;
  --success: 22 163 74;
  --warning: 217 119 6;
  --error: 220 38 38;
  --disabled: 198 198 198;
  --transparent: transparent;
  --avatar-bg: 255 255 255;
  --feedback-bg:250 191 95;
  --dashboard-card:255 255 255;
  --nhs-bg:0 104 179;
  --shsc-bg:0 0 0
}

/* Dyslexia */

.theme-e {
  --primary: 236 227 226;
  --border-card:236 227 226;
  --primary-content: 11 24 30;
  --secondary: 236 227 226;
  --secondary-content: 11 24 30;
  --accent: 31 64 112;
  --accentCard: 31 64 112;
  --accent-content: 255 255 255;
  --neutral: 247 247 247;
  --buttonColor: 18 131 47;
  --buttonText: 247 247 247;
  --card: 255 255 255;
  --card-content: 255 255 255;
  --cardContent: 255 255 255;
  --container: 243 242 241;
  --outline: 96 96 96;
  --info: 101 60 136;
  --success: 22 163 74;
  --warning: 217 119 6;
  --error: 220 38 38;
  --disabled: 198 198 198;
  --transparent: transparent;
  --avatar-bg: 255 255 255;
  --feedback-bg:250 191 95;
  --dashboard-card:236 227 226;
  --nhs-bg:0 104 179;
  --shsc-bg:0 0 0
}

.theme-f {
  --primary: 245 218 137;
  --border-card: 0 21 71;
  --primary-content: 0 21 71;
  --secondary: 245 218 137;
  --secondary-content: 46 36 78;
  --accent: 0 21 71;
  --accentCard: 245 218 137;
  --accent-content: 46 36 78;
  --neutral: 240 240 240;
  --buttonColor: 0 21 71;
  --buttonText: 255 255 255;
  --card: 245 218 137;
  --card-content: 245 218 137;
  --cardContent: 0 21 71;
  --container: 255 255 255;
  --outline: 112 112 112;
  --info: 0 21 71;
  --success: 22 163 74;
  --warning: 217 119 6;
  --error: 220 38 38;
  --disabled: 65	65	65;
  --transparent: transparent;
  --avatar-bg: 245 218 137;
  --feedback-bg:250 191 95;
  --dashboard-card:245 218 137;
  --nhs-bg:0 104 179;
  --shsc-bg:0 0 0
}

/* BASE CSS */

body {
  font-family: Montserrat, sans-serif;
}

h1 {
  font-size: 1.625rem;
  line-height: 2.125rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

h2 {
  font-size: 1.375rem;
  line-height: 1.875rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  h2 {
    font-size: 1.625rem;
    line-height: 2.125rem;
  }
}

h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

h4 {
  font-size: 1.125rem;
  line-height: 1.625rem;
}

@media (max-width: 768px) {
  h4 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

h5 {
  font-size: 0.875rem;
  line-height: 1.375rem;
}

@media (max-width: 768px) {
  h5 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

h6 {
  font-size: 0.875rem;
  line-height: 1.375rem;
}

@media (max-width: 768px) {
  h6 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

p {
  font-size: 0.875rem;
  line-height: 1.375rem;
}

@media (max-width: 768px) {
  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

label {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}

legend {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}

/* END */

.table-blk > * {
  font-family: Montserrat, sans-serif;
}

.table-blk .rdt_TableBody > * {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(var(--primary-content) / var(--tw-text-opacity));
}

.table-blk .rdt_Table {
  font-size: 1.125rem !important;
  line-height: 1.625rem !important;
}

.table-blk .rdt_TableHeadRow {
  border-width: 2px !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
  border-bottom-width: 1px !important;
  border-top-width: 1px !important;
  border-style: solid !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(var(--outline) / var(--tw-border-opacity)) !important;
  padding: 1rem !important;
  text-align: left !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-weight: 700 !important;
}

.table-blk .rdt_TableCol {
  padding: 0px;
}

.table-blk .rdt_TableRow {
  border-bottom-width: 0px !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.table-blk .rdt_TableCell {
  padding-left: 0px;
}

.table-blk .rdt_TableRow button {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  display: flex;
  height: 35px;
  min-width: 75px;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  padding: 0px;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 700;
}

.table-blk .secondary-btn {
  min-width: 150px;
}

@media (max-width: 768px) {
  .table-blk .secondary-btn {
    min-width: 100px;
  }
}

@media (max-width: 420px) {
  .table-blk .secondary-btn {
    min-width: 150px;
  }
}

.table-blk .footer-table-btn {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 639px) {
  .table-blk .footer-table-btn {
    justify-content: flex-end;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

.table-blk .footer-table-btn .success-btn {
  width: 250px;
}

.table-blk .rdt_Pagination {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--primary-content) / var(--tw-text-opacity)) !important;
}

.table-blk .rdt_Pagination span:nth-child(1),
.gnaqej {
  display: none;
}

.table-blk .rdt_Pagination span:nth-child(2) {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--neutral) / var(--tw-text-opacity)) !important;
}

.table-blk .rdt_Pagination button svg path:nth-child(1) {
  fill: rgb(var(--secondary) / 1);
}

.table-blk .data_table_header {
  width: 100%;
}

.table-blk .table_btn_block {
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
}

.table-blk .rdt_Pagination button:hover:not(:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--outline) / var(--tw-bg-opacity));
}

.no-data {
  display: flex;
  min-height: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(var(--outline) / var(--tw-border-opacity));
}

/* @import "./components/language/language.css";
@import "./components/styles/toggle.css";
@import "./components/dropDown/drop-down.css";
@import "./components/backButton/back-button.css";
@import "./components/soundSelector/soundSelector.css";
@import "./components/avatarOverlay/avatarOverlay.css";
@import "./components/styles/card.css";
@import "./components/avatarSelector/avatarSelector.css"; */

/* @import 'tailwindcss/base'; */

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.bottom-6 {
  bottom: 1.5rem !important;
}

.left-7 {
  left: 1.75rem !important;
}

.bottom-10 {
  bottom: 2.5rem !important;
}

.order-4 {
  order: 4 !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mb-24 {
  margin-bottom: 6rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-28 {
  margin-bottom: 7rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-\[120px\] {
  margin-top: 120px !important;
}

.mb-\[34px\] {
  margin-bottom: 34px !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.flex {
  display: flex !important;
}

.table {
  display: table !important;
}

.grid {
  display: grid !important;
}

.hidden {
  display: none !important;
}

.h-full {
  height: 100% !important;
}

.h-2 {
  height: 0.5rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-3 {
  height: 0.75rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-12 {
  height: 3rem !important;
}

.h-14 {
  height: 3.5rem !important;
}

.h-\[45px\] {
  height: 45px !important;
}

.w-full {
  width: 100% !important;
}

.w-12 {
  width: 3rem !important;
}

.max-w-\[400px\] {
  max-width: 400px !important;
}

.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.resize {
  resize: both !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-center {
  align-items: center !important;
}

.items-baseline {
  align-items: baseline !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.border-none {
  border-style: none !important;
}

.bg-transparent {
  background-color: var(--transparent) !important;
}

.fill-buttonColor {
  fill: rgb(var(--buttonColor) / 1) !important;
}

.stroke-current {
  stroke: currentColor !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.align-middle {
  vertical-align: middle !important;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-primaryContent {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--primary-content) / var(--tw-text-opacity)) !important;
}

.text-neutral {
  --tw-text-opacity: 1 !important;
  color: rgb(var(--neutral) / var(--tw-text-opacity)) !important;
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
}

@media (max-width: 768px) {
  .max_md\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .max_md\:mt-8 {
    margin-top: 2rem !important;
  }

  .max_md\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .max_md\:h-\[50px\] {
    height: 50px !important;
  }

  .max_md\:w-\[44px\] {
    width: 44px !important;
  }

  .max_md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}

@media (max-width: 530px) {
  .max_s\:mb-\[100px\] {
    margin-bottom: 100px !important;
  }
}
