.headerText {
  @apply text-base mb-2 font-bold;
}
.head-container{
    @apply flex items-center rounded-sm
}
.feedback-header {
  @apply bg-feedbackBg px-5 py-11 rounded-[20px] relative;
}
.headerText path {
  @apply fill-primaryContent;
}
.head-text{
    @apply ml-2
}
.feedback-btn{
@apply absolute bg-transparent border-transparent right-[1%] top-[5%]
}
.feedback-textarea{
  @apply w-11/12 mt-5 mx-auto border border-solid border-disabled/50 rounded-sm p-5 min-h-[145px]
}
.feedback-btn path{
  @apply fill-primaryContent
}