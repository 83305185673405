.tickbox-group {
  @apply block relative pl-8 mb-3 cursor-pointer text-sm text-primaryContent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tickbox-group input {
  @apply opacity-0 absolute cursor-pointer h-0 w-0;
}

.tickmark {
  @apply absolute left-0 top-0 border border-solid border-primaryContent h-[23px] w-[23px]
}

.tickmark:after {
  @apply absolute hidden content-[""];
  content: "";
}

.tickbox-group input:checked~.tickmark:after {
  @apply block;
}

.tickbox-group input:checked~.tickmark {
  @apply border border-buttonText shadow-small bg-buttonColor;
}

.tickbox-group input:disabled~.tickmark:after {
  @apply border border-black;
}

.tickbox-group input:disabled~.tickmark {
  @apply bg-disabled shadow-none;
}

.tickbox-group input:disabled:checked~.tickmark {
  @apply bg-disabled border-disabled shadow-none;
}

.tickbox-group .tickmark:after {
  @apply left-[8px] top-[3px] w-[6px] h-[12px] border-0 border-r-2 border-b-2 border-solid border-buttonText;
  /* left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1px 1px 0; */
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}