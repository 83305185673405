/* Common pdf css */

table {
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* common end */

.pdf_header {
  @apply w-full min-h-[95px] bg-primary py-[40px] px-[50px] mb-8 overflow-hidden;
}

.pdf_qr_header {
  @apply w-full min-h-[95px] bg-[#1C5DAF] py-[40px] px-[50px] mb-8 overflow-hidden;
}

.pdf_qr_header h1,
.pdf_qr_header .output-group p {
  @apply text-white
}

.pdf_header h1 {
  @apply text-lg font-semibold text-primaryContent mb-2;
}

.pdf_header p {
  @apply text-left text-sm text-primaryContent mb-1;
}

.pdf_body {
  @apply mb-4 overflow-y-auto px-[40px] pb-8;
  min-height: 250px;
}

.responses-view-block {
  @apply mx-auto mb-4;
}

.view-data-group {
  @apply mb-7;
}

.view-data-group > label:first-child,
.view-data-group .input-group label,
.view-data-group .dropdown-label {
  @apply mb-4 inline-block text-base font-bold;
}

.pdf_section p {
  @apply text-base text-primaryContent font-medium;
}

.output-group {
  @apply flex flex-wrap;
}

.view-data-group h2 {
  @apply text-lg text-primaryContent mb-4;
}

.hr-line {
  @apply relative my-8 h-[1px] border border-solid border-outlineColor/20;
}

.ques-checkbox {
  @apply inline-flex items-center mr-4 mb-0 flex-col;
}

.ques-checkbox:last-child {
  @apply mr-0;
}

.ques-checkbox::before {
  @apply h-[52px] w-[52px] border border-solid m-5 rounded-full flex justify-center items-center;
  content: attr(ques-value);
}

.pdf_section {
  @apply max-w-[900px] m-auto relative min-h-[100vh];
}

.pdf_footer {
  width: 90px;
  margin-right: 65px;
  margin-bottom: 25px;
  position: absolute;
  bottom: 0px;
  right: -25px;
}

tfoot {
  @apply relative h-[100px];
}

.pdf_footer_block {
  @apply h-[100px] bg-white;
}

.mindwave-print-footer {
  @apply relative;
}

.mindwave-print-footer img {
  @apply m-auto mt-[20px];
}

.responses-view-block .view-data-group:last-child .hr-line {
  @apply hidden;
}

.pdf-info-card {
  @apply bg-accent min-h-[80px] flex justify-center items-center p-5;
}

.print-icon-btn {
  @apply inline-block absolute right-5 top-[-50px] cursor-pointer;
}

.print-main-section {
  @apply bg-accent hidden mt-[198px];
  min-height: calc(100vh - 355px);
}

.print-head-icon-block {
  @apply mb-[45px];
}

.pdf_footer_block {
  @apply hidden;
}

@media print {

  .pdf-page .header-wrap,
  .pdf-page .footer-section,
  .pdf-page .avathar-img-block,
  .pdf-page .print-head-icon-block {
    @apply hidden;
  }

  .print-main-section {
    @apply grid;
  }

  .pdf_footer_block {
    @apply block w-full;
    position: fixed;
    bottom: 0;
    right: 0;
  }

  .pdf-page .content-container {
    @apply p-0;
  }

  .pdf-page main.px-5 {
    @apply p-0 !important;
  }

  .view-data-group {
    page-break-inside: avoid;
  }

  .print-main-section {
    page-break-after: always;
  }

  .pdf_header {
    @apply absolute top-0;
  }

  .pdf_footer_block {
    @apply block;
  }

  @page {
    size: A4;
    margin: 0px;
  }

  .back-section {
    display: none;
  }

  .content-container {
    padding: 25px 30px;
  }

  .qr-code-section {
    width: 500px;
    height: 500px;
    margin: auto;
    min-height: calc(100vh - 450px);
  }
}