.translated-ltr body {
  @apply top-0 !important;
}
.goog-te-banner-frame {
  @apply hidden mt-5;
}
.goog-logo-link {
  @apply hidden !important;
}
.goog-te-gadget {
  @apply !text-[0px] bg-transparent;
}
.goog-te-gadget .goog-te-combo {
  @apply pt-1.5;
}
.lang-change-text {
  @apply pr-5;
}

/* Language dropdown look and feel */
.goog-te-gadget select {
  @apply px-1 py-2 text-primaryContent font-primary font-semibold outline-none border-0 bg-transparent border-b border-solid border-primaryContent;
}

/* For admin reseting skin */
.header-wrap.no-skin .goog-te-gadget select {
  @apply text-white border-white;
}
.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none !important;
}
.skiptranslate iframe  {
  display: none !important;
}

.lang-flex.translation-section .goog-te-gadget select option {
  color: #000 !important;
}
