/* back btn */
.back_block {
  @apply inline-flex items-center cursor-pointer;
}

.back_block p {
  @apply ml-3;
}

.back-section {
  @apply flex justify-between mb-4;
}
/* back btn end */

/*  Logout */
.logout-block {
  @apply inline-flex items-center cursor-pointer;
}

.logout-block p {
  @apply ml-3;
}

/*  Logout End */

.head-info-block {
  @apply mb-[40px];
}

.head-info-block h1 {
  @apply mb-6;
}

.head-info p {
  @apply text-lg mb-7;
}

.head-info p span {
  @apply text-lg font-bold;
}
