.card-container {
  @apply bg-card text-primaryContent border border-solid border-outlineColor/50 rounded-[24px];
}
.card-title {
  @apply bg-accent text-cardContent px-6 py-4 flex justify-center text-lg font-bold rounded-t-[24px] border-0 border-b border-solid border-outlineColor/50 min-h-[59px];
}
.title-alignment {
  @apply justify-start pl-12;
}
.card-spacing {
  @apply p-12 max_md:p-10 max_sm:p-8 max_xs:p-4 min-h-[400px] relative ;
}
.card-spacing.bottom-padding{
  @apply pb-32;
}
.card-content h3 {
  @apply text-base mb-8 font-semibold;
}
.card-content p {
  @apply text-base mb-4;
}
.card-content p {
  @apply max_s:text-sm;
}
.dorpDown-containter{
  @apply grid w-[62%] max_md:w-full mb-5 
}