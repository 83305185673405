.title-header {
  @apply text-center text-[#1C5DAF] pb-5 text-8xl;
}

.unit-card {
  @apply bg-slate-50 border border-solid rounded-md p-10;
}

.unit-card p {
  @apply p-1;
}

.unit-button {
  @apply bg-[#1C5DAF] text-white min-w-[160px] min-h-[40px] rounded-md border-0 font-semibold shadow-small hover:shadow-big max_s:mb-3;
}

.form-container {
  @apply grid gap-8 max_sm:grid-cols-1 grid-cols-2;
}
.map-preview-blk{
  @apply bg-neutral grid relative p-8 max_s:py-5 max_s:px-3;
}
.map-label{
  @apply text-lg text-center;
  color: #222222;
}
.unit-map-preview{
  @apply m-2
}
.success-card{
  @apply bg-neutral flex flex-col justify-center text-center px-4 py-8 rounded-sm;
}
.success-title{
  @apply text-2xl mb-2
}
.success-msg{
  @apply text-lg mb-2
}
.preview-blk{
  @apply flex !flex-col justify-center text-center mx-8 max_s:mb-4
}
.preview-blk p{
  @apply mb-2
}
.add-unit-btn{
  @apply flex justify-center mt-5
}
.unit-region .react-select__control{
  border: 1px solid #222222 !important;
  border-radius: 5px !important;
}
.map-view-header{
  @apply flex justify-center items-start;
}
.refresh-btn{
  @apply absolute right-[10px] top-[16px] cursor-pointer w-[36px] h-[36px] max_s:w-[24px] max_s:h-[24px];
}
.preview-msg{
  @apply mb-3 text-base;
}