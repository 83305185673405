.avatar-selector-container {
  @apply grid grid-cols-3 gap-9;
}
-r .avatar-selector-wapper {
  @apply flex items-center justify-between mb-9;
}
/* .sound-icon {
  @apply mr-14;
} */
.avatar-selector {
  @apply flex relative;
}
.avatar-selector img {
  @apply rounded-[30px];
}
.avatar-selector img {
  @apply max_md:rounded-sm;
}
.avatar-selector img {
  @apply max_s:rounded-[10px];
}
.avatar-selector input[type="radio"] {
  @apply w-40 h-40 absolute opacity-0 left-0;
}
.avatar-selector input[type="radio"] {
  @apply max_md:w-28 max_md:h-28;
}
.avatar-selector input[type="radio"] {
  @apply max_s:w-20 max_s:h-20;
}
.avatar-selector input[type="radio"]:focus-visible ~ .icon-label {
  @apply outline-offset-2 outline;
}
.ticked-img {
  @apply absolute top-3 right-3;
}

.ticked-img {
  @apply max_s:absolute max_s:top-[5px] max_s:right-[5px];
}

.avatar-selector .label-text {
  @apply text-center   cursor-pointer relative;
}
.avatar-selector-size-default .label-text {
  @apply py-6 px-10 text-base;
}
.label-text{
  @apply rounded-[30px] border border-solid border-disabled
}
