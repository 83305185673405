.sound-radio-container {
  @apply flex flex-col;
}
.sound-radio-wapper {
  @apply max_sm:grid max_sm:grid-cols-1 max_sm:gap-2 flex items-center justify-between mb-9;
}
.inner-sound-container {
  @apply flex items-center w-[230px] max_xs:w-auto justify-between;
}
.sound-icon {
  @apply flex;
}
.sound-radio {
  @apply flex relative w-[160px] h-[46px];
}
.sound-radio input[type="radio"] {
  @apply w-40 h-11 absolute opacity-0 left-0 cursor-pointer;
}
.sound-radio input:checked + .label-text {
  @apply text-buttonText bg-buttonColor border border-solid border-outlineColor;
}
.sound-radio input[type="radio"]:focus-visible ~ .icon-label {
  @apply outline-offset-2 outline;
}
.sound-radio p {
  @apply inline-block absolute -top-5 right-5 z-10 m-0;
  transform: translate(0, 20%);
}

.sound-radio .label-text {
  @apply flex text-center border border-solid rounded-full w-full items-center justify-center;
}
.sound-radio-size-default .label-text {
  @apply py-6 px-10 text-base;
}

.sound-radio-container div svg {
  @apply fill-primaryContent;
}
