.checkbox-block {
  @apply grid gap-5 w-full justify-center;
  grid-template-columns: repeat(auto-fit, minmax(140px, max-content));
}
.round-block {
  @apply flex justify-between w-11/12 m-auto gap-x-2;
}
.round-block-warpper {
  @apply flex flex-col items-center;
}
.round-block-warpper label {
  @apply flex m-4 max_xs:m-[10px];
}
.round-block-warpper div p {
  @apply max_xs:text-[12px] text-center break-words;
}
.question-title{
  @apply font-bold mb-7
}
.question-sub-title{
  @apply !font-normal
}
.question-sub-title span{
  @apply mr-1
}
.text-area-container{
  @apply w-full max-h-40 resize-none rounded-sm p-5 border-disabled/50
}
.checkbox-group {
  @apply flex relative h-[142px] w-[140px] justify-center items-center bg-accent text-cardContent rounded-md border border-solid border-outlineColor/50 cursor-pointer;
}
.round-group {
  @apply flex relative h-[52px] w-[52px] justify-center items-center rounded-full border hover:border-2 border-solid border-outlineColor hover:border-buttonColor max_xs:h-8 max_xs:w-8;
}
.checkbox-group input {
  @apply absolute h-[142px] w-[140px] left-0 opacity-0 cursor-pointer;
}
.round-group input {
  @apply absolute h-[52px] w-[52px] left-0 opacity-0 max_xs:h-8 max_xs:w-8 cursor-pointer disabled:cursor-default;
}
.checkbox-group input:checked ~ .checkmark {
  @apply bg-buttonColor text-buttonText h-[140px] w-[140px] flex justify-center items-center rounded-md border border-solid border-outlineColor border-transparent;
}
.round-group input:checked ~ .checkmark {
  @apply bg-buttonColor text-buttonText h-[52px] w-[52px] flex justify-center items-center rounded-full max_xs:h-8 max_xs:w-8;
}
.checkmark {
  @apply p-6 max_md:p-4  text-base text-center;
}
.feedback-container .round-group input:checked ~ .checkmark {
  @apply bg-buttonColor
}
.feedback-container .round-group{
  @apply hover:border hover:border-outlineColor
}
.text-below-textarea{
  @apply text-right italic
}