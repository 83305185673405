.footer-wrap {
  @apply bg-secondary min-h-[143px] max_md:min-h-[auto] text-primaryContent border-0 border-t-2 border-solid border-outlineColor/30;
}
.footer-container {
  @apply px-16 py-4 flex justify-between items-center;
}
.footer-container {
  @apply max_md:px-14 max_md:py-10;
}
.footer-container {
  @apply max_sm:px-10 max_sm:py-8;
}
.footer-link {
  @apply text-base text-primaryContent font-bold no-underline;
}
.mindwave-footer {
  @apply p-6 text-center border-2 border-solid border-[#42B242] bg-white;
}
.mindwave-footer img {
  @apply mt-4 inline-block;
}

/* For admin reseting skin */
.footer-wrap.no-skin {
  @apply bg-white;
}

.footer-content{
  @apply flex gap-7 max_md:block max_md:text-center
}
.nhs-logo-bg{
  @apply w-36
}
.nhs-logo-background{
  @apply fill-nhsBg
}
.shsc-logo-bg{
  @apply fill-shscBg
}