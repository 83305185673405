.theme-radio {
    @apply inline-block cursor-pointer font-medium relative mb-1;
  }
  .theme-radio input {
    @apply absolute;
    left: -9999px;
  }
  .theme-radio span {
    @apply flex items-center rounded-full ml-5;
    transition: 0.25s ease;
  }
  .theme-radio span {
    @apply flex items-center rounded-full ml-5;
    transition: 0.25s ease;
  }
  .theme-radio span {
    @apply max_s:ml-5;
  }
  .theme-radio span {
    @apply max_xs:ml-4;
  }
  .theme-wrap {
    @apply relative;
  }
  .theme-wrap .theme-name {
    @apply absolute -bottom-7 left-0 right-0 mt-3 text-sm max_md:text-[10px] text-center font-bold;
  }
  .theme-radio span:before {
    @apply flex bg-neutral w-[30px] max_md:w-[14px] h-[30px] max_md:h-[14px] rounded-full outline outline-2 outline-offset-[3px] outline-transparent;
    flex-shrink: 0;
    content: "";
    transition: 0.25s ease;
  }
  .theme-radio span:before {
    @apply max_md:w-[20px] max_md:h-[20px];
  }
  .theme-radio span:before {
    @apply max_sm:w-[14px] max_sm:h-[14px];
  }
  
  /* Theme A colours should be changed here */
  .theme-radio input#theme--1 + span:before {
    @apply bg-[#1F775E];
  }
  .theme-radio input:checked#theme--1 + span:before {
    @apply outline-[#1F775E];
  }
  /* Theme B colours should be changed here */
  .theme-radio input#theme--2 + span:before {
    @apply bg-[#1D1B1E];
  }
  .theme-radio input:checked#theme--2 + span:before {
    @apply outline-[#BCBCBC];
  }
  /* Theme C colours should be changed here */
  .theme-radio input#theme--3 + span:before {
    @apply bg-[#E4794D];
  }
  .theme-radio input:checked#theme--3 + span:before {
    @apply outline-[#E4794D];
  }
  /* Theme D colours should be changed here */
  .theme-radio input#theme--4 + span:before {
    @apply bg-[#D3D1F8];
  }
  .theme-radio input:checked#theme--4 + span:before {
    @apply outline-[#D3D1F8];
  }
  /* Theme E colours should be changed here */
  .theme-radio input#theme--5 + span:before {
    @apply bg-[#1F4070];
  }
  .theme-radio input:checked#theme--5 + span:before {
    @apply outline-[#1F4070];
  }
  /* Theme F colours should be changed here */
  .theme-radio input#theme--6 + span:before {
    @apply bg-[#001547];
  }
  .theme-radio input:checked#theme--6 + span:before {
    @apply outline-[#001547];
  }