.button-section {
  @apply bg-buttonColor text-buttonText min-w-[160px] min-h-[46px] rounded-md border-0 font-semibold shadow-small hover:shadow-big;
}
.button-section:disabled {
  @apply bg-disabled text-buttonText cursor-default hover:shadow-none;
}
.soundApplyBtn .button-section:disabled {
  @apply bg-disabled/70 text-buttonText cursor-default hover:shadow-none;
}

.btn-disable {
  @apply bg-disabled text-buttonText cursor-default
}

.invalid-card {
  @apply flex justify-center items-center bg-container text-primaryContent rounded-[8px] border-0 p-2 min-h-[80px];
}
.success-blk .button-section{
  @apply min-w-[210px]
}
.tac-backBtn{
  @apply absolute left-8
}
.tac-footer-container{
  @apply text-center max_s:mb-[100px] flex items-center justify-center flex-col gap-8 mb-2
}
.tac-footer-container a{
  @apply font-bold text-primaryContent
}