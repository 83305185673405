.header-title {
  @apply mb-24 text-center;
}
.header-title {
  @apply max_md:mb-16 max_md:text-xl;
}
.header-title {
  @apply max_s:mb-8 max_s:text-base;
}
.avatar-container {
  @apply mt-20 mb-24;
}
.avatar-container {
  @apply max_md:mt-10 max_md:mb-12;
}
.avatar-container {
  @apply max_s:mt-5 max_s:mb-6;
}
.avatar-submit-container {
  @apply flex flex-col items-center;
}
.avatar-submit-container div {
  @apply mb-9;
}
.avatar-submit-container a {
  @apply text-primaryContent font-bold text-base w-[150px] hover:text-buttonColor;
}
