.toggle-wrapper {
  @apply grid grid-cols-2 w-fit items-center;
}
.toggle-wrapper .option {
  @apply flex justify-center w-full h-[37px] items-center cursor-pointer;
}
.toggle-wrapper .option.disabled {
  @apply cursor-default;
}
.on-button {
  @apply px-1 border border-solid border-outlineColor rounded-l-[8px];
}
.off-button {
  @apply px-1 border border-solid border-outlineColor rounded-r-[8px];
}
.on-toggle {
  @apply hidden;
}
.off-toggle {
  @apply hidden;
}
.on-toggle:checked ~ .on-button {
  @apply bg-buttonColor rounded-l-[8px];
}
.off-toggle:checked ~ .off-button {
  @apply bg-buttonColor rounded-r-[8px];
}
.on-toggle:checked ~ .on-button span,
.off-toggle:checked ~ .off-button span {
  @apply text-buttonText;
}
.on-toggle:checked ~ .on-button .input-text,
.off-toggle:checked ~ .off-button .input-text {
  @apply bg-buttonColor;
}
.on-toggle:checked ~ .full-content span,
.off-toggle:checked ~ .part-content span {
  @apply text-primaryContent;
}
.on-toggle ~ .full-content span,
.off-toggle ~ .part-content span {
  @apply text-disabled;
}
.toggle-wrapper.w-toggle {
  @apply w-9/12 max_md:w-full max_sm:grid-cols-1;
}
.toggle-wrapper.w-toggle .option {
  @apply justify-start h-auto;
}
