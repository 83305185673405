@media (max-width: 410px) {
  .react-datepicker__month-container,
  .react-datepicker__time-container {
    @apply w-full !important;
  }
  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 5px !important;
  }
}

.react-datepicker-popper {
  z-index: 20 !important;
}

.rang-date-block {
  @apply min-h-[53px] min-w-[220px] grid  border border-solid rounded-[5px] items-center;
}

.rang-date-block input {
  @apply !border-0 min-h-[30px] rounded-[5px] px-2 min-w-[210px] !m-auto;
}

/* .rang-date-block .input-group:first-child input {
  @apply border-r rounded-none;
} */

.rang-date-block input:focus-visible {
  @apply outline-none;
}

.react-datepicker {
  @apply max_md:inline-block flex !important;
}
