/* Error messages style */
.error-block {
  @apply p-8 rounded-md flex flex-col text-center my-4;
  background-color: #f4cfcf;
}

.error-block p {
  @apply !m-0
}

/* End of error messages style */