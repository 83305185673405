.feedback-info-block h1 {
  @apply text-2xl max_md:mb-6 mb-9;
}

.feedback-info-block p {
  @apply mb-7;
}

.feedback-info {
  @apply grid max_md:grid-cols-1 grid-cols-3 gap-5;
}

.feedback-info p {
  @apply text-base mb-0;
}

.feedback-info p span {
  @apply text-base font-bold;
}

.dashboard-block {
  @apply max-w-md m-auto max_sm:my-[35px] my-[50px];
}

.dashboard-block h1 {
  @apply text-buttonColor text-center max_sm:mb-10 text-8xl mb-14;
}

.dashboard-block p {
  @apply text-center mb-14 text-lg;
}

.dashboard-block p span {
  @apply font-bold;
}

.no-data-block {
  @apply min-h-[300px] flex justify-center items-center border border-solid border-outlineColor rounded-sm;
  background-color: rgba(0, 0, 0, 0.05);
}

.no-data-block p {
  @apply mb-0 text-base;
}

.report-card-section {
  @apply grid grid-cols-4 max_md:grid-cols-2 max_s:gap-3 gap-10;
}

.report-card-block {
  @apply cursor-pointer flex flex-col justify-between max-w-[180px] max_s:max-w-[150px] min-h-[180px] max_s:min-h-[150px] max_md:max-w-[100%] border-buttonColor border-[2px] border-solid rounded-sm p-3 w-full;
}

.report-card-block:hover {
  @apply bg-buttonColor;
}

.report-card-block p {
  @apply text-2xl font-bold text-buttonColor;
}

.report-card-block:hover p {
  @apply text-white;
}

.report-card-block:hover svg path {
  @apply fill-white;
}

.map-section {
  @apply flex justify-center mb-12 w-full min-h-[800px] border border-solid rounded-sm border-outlineColor/50 mt-6;
}
.legend-container {
  /* @apply flex justify-evenly; */
  @apply grid grid-cols-3 max_s:grid-cols-1 gap-3;
}
.legend-wapper {
  @apply flex items-center;
}
.legend-color {
  @apply max-w-[32px] w-full h-8 mr-4 flex rounded-[5px];
}
.legend-color.color-legendlow {
  @apply bg-legendlow;
}
.legend-color.color-legendModeratelow {
  @apply bg-legendModerate;
}
.legend-color.color-legendSatisfactory {
  @apply bg-legendSatisfactory;
}
.legend-color.color-legendGood {
  @apply bg-legendGood;
}
.legend-color.color-legendGreat {
  @apply bg-legendGreat;
}
.legend-text {
  @apply font-bold;
}
.seemore-block {
  @apply flex justify-end;
}

.seemore-block button {
  @apply bg-transparent border-0 mt-3;
}

.title-section h1 {
  @apply text-6xl mb-6;
}
.title-section p {
  @apply text-lg;
}
.highcharts-label.highcharts-tooltip.highcharts-color-1 path {
  @apply fill-white stroke-[#00000042];
}
a.external-link-blk{
  @apply text-primaryContent font-bold;
  /* @apply text-[#1c5daf] font-bold */
}
.external-link-blk{
@apply break-all
}
/* Report pilot message */
.report-pilot-text{
  @apply text-start mb-6 !important
}