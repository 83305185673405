.avathar-img-block {
  @apply absolute inset-0;
}
.avatar-block {
  @apply bg-info w-40 max_md:w-[134px] max_s:w-20 rounded-r-full;
}
.avathar-img {
  @apply flex items-center justify-end p-1;
}
.avathar-img p {
  @apply pr-5 text-base text-neutral;
}
.avathar-img svg, .avathar-img img {
  @apply w-16 max_md:w-[46px] max_s:w-9 rounded-full h-auto;
}
.avathar-img path {
  @apply fill-avatarBg
}