.stepper-wrap {
  @apply flex overflow-x-auto;
}

.stepper-wrap ul {
  @apply list-none p-0 inline-flex items-center justify-center;
}
.stepper-wrap li {
  @apply w-8 h-8 max_s:w-7 max_s:h-7 flex items-center justify-center text-base max_s:text-sm font-semibold border border-solid border-buttonColor rounded-full;
}
.stepper-wrap li:last-child{
  @apply hidden
}
.stepper-wrap span {
  @apply w-8 h-[1px] bg-buttonColor;
}
.stepper-wrap ul span:last-of-type{
  @apply hidden
}
.stepper-wrap li.active {
  @apply bg-buttonColor text-buttonText;
}

/* Hold min height till stepper comes in */
.stepper-spacer {
  @apply min-h-[90px] overflow-hidden;
}
.step-footer {
  @apply absolute left-8 right-8 bottom-8 flex items-center justify-between;
}
.step-footer a {
  @apply -mt-4 text-base leading-4 font-bold text-primaryContent no-underline border-0 border-b-2 border-solid;
}
.step-footer button {
  @apply text-primaryContent hover:text-buttonColor fill-primaryContent hover:fill-buttonColor;
}
.step-footer button:disabled {
  @apply text-primaryContent/50 hover:text-primaryContent/50;
}
.step-footer button:disabled .icon-view {
  @apply fill-primaryContent/50 hover:fill-primaryContent/50;
}
.style-quest {
  @apply italic mt-2;
}
.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}