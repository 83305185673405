.header-wrap {
  @apply bg-primary min-h-header max_md:min-h-[190px] max_md:min-h-[150px] text-primaryContent border-0 border-b-2 border-solid border-outlineColor/30 flex items-center;
}
.header-container {
  @apply py-11 pr-11 pl-14 w-full;
}
.header-container {
  @apply max_md:py-9 max_md:pr-9 max_md:pl-12;
}
.header-container {
  @apply max_sm:py-4 max_sm:pr-5 max_sm:pl-5;
}
.text-logo {
  @apply text-3xl font-bold max-w-[130px];
}
.text-logo {
  @apply max_md:text-xl;
}
.logo-link {
  @apply no-underline text-primaryContent hover:text-primaryContent flex items-center hover:opacity-80;
}
.header-home-icon {
  @apply fill-primaryContent;
}

/* For admin reseting skin */
.header-wrap.no-skin {
  @apply bg-[#1C5DAF] text-white;
}
.header-wrap.no-skin .logo-link {
  @apply text-white;
}
.userLoginHeader {
  @apply relative text-end;
}
.userLoginHeader .lang-flex {
  @apply absolute right-0 top-12;
}
