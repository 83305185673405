.sec-header-text {
  @apply font-normal;
}
.answer-outer {
  @apply flex justify-between mt-5 mb-10 w-4/5 mx-auto;
}
.answer-inner {
  @apply flex flex-col items-center w-20;
}
.optionCount {
  @apply w-14 h-14 rounded-full border border-primaryContent border-solid flex items-center justify-center;
}
.selectedAnswer {
  @apply bg-[#707070] text-neutral;
}
.editBtn {
  @apply w-11/12 flex justify-end m-auto relative top-[-25px];
}
.editBtn button {
  @apply bg-transparent border-transparent text-primaryContent border-b-2 border-b-primaryContent border-solid p-0 absolute;
}
.feedback-footer {
  @apply flex justify-between mt-12;
}
