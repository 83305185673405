.background-section .inner-card {
  @apply w-[150px] h-[150px] rounded-[26px] mb-6 cursor-pointer border border-solid border-outlineColor flex items-center justify-center;
}

.avatar-selector .bg-item-section {
  @apply max_md:w-28 max_md:h-28 max_s:w-20 max_s:h-20 w-40 h-40 border border-solid border-outlineColor rounded-md max_md:rounded-sm max_s:rounded-[10px];
}

.bg-label {
  @apply text-center text-base py-2.5 font-semibold;
}

.background-section .avatar-selector-container {
  @apply grid grid-cols-2 gap-9 place-items-center border-none;
}
.avatar-selector input[type="radio"]:focus-visible ~ .label-text {
  @apply outline-offset-2 outline;
}
.background-img {
  @apply w-40 h-40 rounded-[30px];
}
.background-img {
  @apply max_md:w-28 max_md:h-28 max_md:rounded-sm;
}
.background-img {
  @apply max_s:w-20 max_s:h-20 max_s:rounded-[10px];
}
