.react-select__control {
  @apply pl-1 border border-outlineColor bg-transparent;
}

.secondary-select .react-select__control--menu-is-open {
  @apply rounded-bl rounded-br !important;
}
.react-select__menu {
  @apply text-left mt-0 shadow-small border border-solid  !important;
}

.react-select__menu-list {
  @apply py-0  !important;
}

.react-select__option {
  @apply pl-4 min-h-[50px] flex justify-start items-center !important;
}

.react-select__option:nth-child(odd) {
  @apply text-primaryContent;
}

.react-select__option:nth-child(even) {
  @apply text-primaryContent;
}

.react-select__option:nth-child(odd):hover {
  @apply bg-outlineColor;
}

.react-select__option:nth-child(even):hover {
  @apply bg-outlineColor;
}

.react-select__option--is-focused {
  @apply bg-buttonColor text-buttonText !important;
}

.react-select__option.react-select__option--is-selected {
  /* @apply bg-primary-100 text-color-light; */
}

.react-select__indicator {
  /* @apply text-primary-100 !important; */
}

.dropdown-heading {
  @apply rounded;
}

.item-renderer span {
  @apply px-2;
}

.item-renderer input[type='checkbox'] {
  @apply appearance-none w-[25px] h-[25px] p-[3px];
  border: 1px solid var(--input) !important;
  background-clip: content-box;
}

.item-renderer input[type='checkbox']:checked {
  /* @apply bg-primary-100; */
}

.dropdown-heading-value:focus,
.dropdown-heading-value:hover {
  @apply outline-none !important;
}

.react-select__option.react-select__option--is-selected {
  @apply bg-buttonColor text-buttonText;
}

.select-dropdown-section .react-select__input {
}

.css-cofman-control {
  @apply border border-outlineColor shadow-none !important;
}

.css-cofman-control:hover {
  @apply border border-outlineColor !important;
}

.secondary-select .react-select__control {
  @apply rounded-[5px] pl-1 border border-outlineColor bg-transparent min-h-[53px];
}

.secondary-select .react-select__option--is-focused {
  @apply bg-gray-100 text-cardContent !important;
}

.secondary-select .react-select__option.react-select__option--is-selected {
  @apply bg-gray-300 text-cardContent;
}
.react-select__placeholder{
  @apply !text-sm !font-primary
}