.input-group {
  @apply relative;
}
.add-unit-block .input-group .text-field {
  @apply m-0;
}

.input-group label,
.dropdown-label {
  @apply text-base block mb-2 font-medium text-left tracking-wide;
}

.input-group p {
  @apply text-base block mb-3;
}

.input-group .text-field:disabled::placeholder {
  @apply text-gray-400;
}

.input-group .text-field:disabled {
  @apply border-gray-200 text-opacity-70 cursor-not-allowed bg-gray-200;
}

.input-group .text-field {
  @apply relative border py-2 px-4 text-sm tracking-wide w-full mb-4;
  box-sizing: border-box;
  letter-spacing: 1px;
  border: 1px solid #222222;
  border-radius: 5px;
}
.unit-loc{
  @apply !mb-3
}
.sample-text{
  color: #222222;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0 !important;
}
.input-group .text-field:focus {
  @apply outline-none border border-outlineColor;
}

.input-group .text-field::placeholder {
  @apply font-normal text-outlineColor;
}

.form-error-msg {
  @apply mt-2 text-left text-sm text-error;
}

.input-group .text-field.input-error {
  @apply border border-error;
}

.input-group .text-field.error {
  @apply border border-solid border-error;
}
.form-error{
  @apply text-error text-base;
}
.add-unit-block .input-group .text-field::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  @apply text-sm font-primary
}
.add-unit-block .input-group .text-field::-moz-placeholder { /* Firefox 19+ */
  @apply text-sm font-primary
}
.add-unit-block .input-group .text-field:-ms-input-placeholder { /* IE 10+ */
  @apply text-sm font-primary
}
.add-unit-block .input-group .text-field:-moz-placeholder { /* Firefox 18- */
  @apply text-sm font-primary
}